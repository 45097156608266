/* Modernizr 2.6.2 (Custom Build) | MIT & BSD
 * Build: http://modernizr.com/download/#-fontface-backgroundsize-borderimage-borderradius-boxshadow-flexbox-flexboxlegacy-hsla-multiplebgs-opacity-rgba-textshadow-cssanimations-csscolumns-generatedcontent-cssgradients-cssreflections-csstransforms-csstransforms3d-csstransitions-applicationcache-canvas-canvastext-draganddrop-hashchange-history-audio-video-indexeddb-input-inputtypes-localstorage-postmessage-sessionstorage-websockets-websqldatabase-webworkers-geolocation-inlinesvg-smil-svg-svgclippaths-touch-webgl-teststyles-testprop-testallprops-hasevent-prefixes-domprefixes
 */
;
window.Modernizr = function(a, b, c) {
    function B(a) {
        i.cssText = a
    }

    function C(a, b) {
        return B(m.join(a + ";") + (b || ""))
    }

    function D(a, b) {
        return typeof a === b
    }

    function E(a, b) {
        return !!~("" + a).indexOf(b)
    }

    function F(a, b) {
        for (var d in a) {
            var e = a[d];
            if (!E(e, "-") && i[e] !== c) return b == "pfx" ? e : !0
        }
        return !1
    }

    function G(a, b, d) {
        for (var e in a) {
            var f = b[a[e]];
            if (f !== c) return d === !1 ? a[e] : D(f, "function") ? f.bind(d || b) : f
        }
        return !1
    }

    function H(a, b, c) {
        var d = a.charAt(0).toUpperCase() + a.slice(1),
            e = (a + " " + o.join(d + " ") + d).split(" ");
        return D(b, "string") || D(b, "undefined") ? F(e, b) : (e = (a + " " + p.join(d + " ") + d).split(" "), G(e, b, c))
    }

    function I() {
        e.input = function(c) {
            for (var d = 0, e = c.length; d < e; d++) t[c[d]] = c[d] in j;
            return t.list && (t.list = !!b.createElement("datalist") && !!a.HTMLDataListElement), t
        }("autocomplete autofocus list placeholder max min multiple pattern required step".split(" ")), e.inputtypes = function(a) {
            for (var d = 0, e, g, h, i = a.length; d < i; d++) j.setAttribute("type", g = a[d]), e = j.type !== "text", e && (j.value = k, j.style.cssText = "position:absolute;visibility:hidden;", /^range$/.test(g) && j.style.WebkitAppearance !== c ? (f.appendChild(j), h = b.defaultView, e = h.getComputedStyle && h.getComputedStyle(j, null).WebkitAppearance !== "textfield" && j.offsetHeight !== 0, f.removeChild(j)) : /^(search|tel)$/.test(g) || (/^(url|email)$/.test(g) ? e = j.checkValidity && j.checkValidity() === !1 : e = j.value != k)), s[a[d]] = !!e;
            return s
        }("search tel url email datetime date month week time datetime-local number range color".split(" "))
    }
    var d = "2.6.2",
        e = {},
        f = b.documentElement,
        g = "modernizr",
        h = b.createElement(g),
        i = h.style,
        j = b.createElement("input"),
        k = ":)",
        l = {}.toString,
        m = " -webkit- -moz- -o- -ms- ".split(" "),
        n = "Webkit Moz O ms",
        o = n.split(" "),
        p = n.toLowerCase().split(" "),
        q = {
            svg: "http://www.w3.org/2000/svg"
        },
        r = {},
        s = {},
        t = {},
        u = [],
        v = u.slice,
        w, x = function(a, c, d, e) {
            var h, i, j, k, l = b.createElement("div"),
                m = b.body,
                n = m || b.createElement("body");
            if (parseInt(d, 10))
                while (d--) j = b.createElement("div"), j.id = e ? e[d] : g + (d + 1), l.appendChild(j);
            return h = ["&#173;", '<style id="s', g, '">', a, "</style>"].join(""), l.id = g, (m ? l : n).innerHTML += h, n.appendChild(l), m || (n.style.background = "", n.style.overflow = "hidden", k = f.style.overflow, f.style.overflow = "hidden", f.appendChild(n)), i = c(l, a), m ? l.parentNode.removeChild(l) : (n.parentNode.removeChild(n), f.style.overflow = k), !!i
        },
        y = function() {
            function d(d, e) {
                e = e || b.createElement(a[d] || "div"), d = "on" + d;
                var f = d in e;
                return f || (e.setAttribute || (e = b.createElement("div")), e.setAttribute && e.removeAttribute && (e.setAttribute(d, ""), f = D(e[d], "function"), D(e[d], "undefined") || (e[d] = c), e.removeAttribute(d))), e = null, f
            }
            var a = {
                select: "input",
                change: "input",
                submit: "form",
                reset: "form",
                error: "img",
                load: "img",
                abort: "img"
            };
            return d
        }(),
        z = {}.hasOwnProperty,
        A;
    !D(z, "undefined") && !D(z.call, "undefined") ? A = function(a, b) {
        return z.call(a, b)
    } : A = function(a, b) {
        return b in a && D(a.constructor.prototype[b], "undefined")
    }, Function.prototype.bind || (Function.prototype.bind = function(b) {
        var c = this;
        if (typeof c != "function") throw new TypeError;
        var d = v.call(arguments, 1),
            e = function() {
                if (this instanceof e) {
                    var a = function() {};
                    a.prototype = c.prototype;
                    var f = new a,
                        g = c.apply(f, d.concat(v.call(arguments)));
                    return Object(g) === g ? g : f
                }
                return c.apply(b, d.concat(v.call(arguments)))
            };
        return e
    }), r.flexbox = function() {
        return H("flexWrap")
    }, r.flexboxlegacy = function() {
        return H("boxDirection")
    }, r.canvas = function() {
        var a = b.createElement("canvas");
        return !!a.getContext && !!a.getContext("2d")
    }, r.canvastext = function() {
        return !!e.canvas && !!D(b.createElement("canvas").getContext("2d").fillText, "function")
    }, r.webgl = function() {
        return !!a.WebGLRenderingContext
    }, r.touch = function() {
        var c;
        return "ontouchstart" in a || a.DocumentTouch && b instanceof DocumentTouch ? c = !0 : x(["@media (", m.join("touch-enabled),("), g, ")", "{#modernizr{top:9px;position:absolute}}"].join(""), function(a) {
            c = a.offsetTop === 9
        }), c
    }, r.geolocation = function() {
        return "geolocation" in navigator
    }, r.postmessage = function() {
        return !!a.postMessage
    }, r.websqldatabase = function() {
        return !!a.openDatabase
    }, r.indexedDB = function() {
        return !!H("indexedDB", a)
    }, r.hashchange = function() {
        return y("hashchange", a) && (b.documentMode === c || b.documentMode > 7)
    }, r.history = function() {
        return !!a.history && !!history.pushState
    }, r.draganddrop = function() {
        var a = b.createElement("div");
        return "draggable" in a || "ondragstart" in a && "ondrop" in a
    }, r.websockets = function() {
        return "WebSocket" in a || "MozWebSocket" in a
    }, r.rgba = function() {
        return B("background-color:rgba(150,255,150,.5)"), E(i.backgroundColor, "rgba")
    }, r.hsla = function() {
        return B("background-color:hsla(120,40%,100%,.5)"), E(i.backgroundColor, "rgba") || E(i.backgroundColor, "hsla")
    }, r.multiplebgs = function() {
        return B("background:url(https://),url(https://),red url(https://)"), /(url\s*\(.*?){3}/.test(i.background)
    }, r.backgroundsize = function() {
        return H("backgroundSize")
    }, r.borderimage = function() {
        return H("borderImage")
    }, r.borderradius = function() {
        return H("borderRadius")
    }, r.boxshadow = function() {
        return H("boxShadow")
    }, r.textshadow = function() {
        return b.createElement("div").style.textShadow === ""
    }, r.opacity = function() {
        return C("opacity:.55"), /^0.55$/.test(i.opacity)
    }, r.cssanimations = function() {
        return H("animationName")
    }, r.csscolumns = function() {
        return H("columnCount")
    }, r.cssgradients = function() {
        var a = "background-image:",
            b = "gradient(linear,left top,right bottom,from(#9f9),to(white));",
            c = "linear-gradient(left top,#9f9, white);";
        return B((a + "-webkit- ".split(" ").join(b + a) + m.join(c + a)).slice(0, -a.length)), E(i.backgroundImage, "gradient")
    }, r.cssreflections = function() {
        return H("boxReflect")
    }, r.csstransforms = function() {
        return !!H("transform")
    }, r.csstransforms3d = function() {
        var a = !!H("perspective");
        return a && "webkitPerspective" in f.style && x("@media (transform-3d),(-webkit-transform-3d){#modernizr{left:9px;position:absolute;height:3px;}}", function(b, c) {
            a = b.offsetLeft === 9 && b.offsetHeight === 3
        }), a
    }, r.csstransitions = function() {
        return H("transition")
    }, r.fontface = function() {
        var a;
        return x('@font-face {font-family:"font";src:url("https://")}', function(c, d) {
            var e = b.getElementById("smodernizr"),
                f = e.sheet || e.styleSheet,
                g = f ? f.cssRules && f.cssRules[0] ? f.cssRules[0].cssText : f.cssText || "" : "";
            a = /src/i.test(g) && g.indexOf(d.split(" ")[0]) === 0
        }), a
    }, r.generatedcontent = function() {
        var a;
        return x(["#", g, "{font:0/0 a}#", g, ':after{content:"', k, '";visibility:hidden;font:3px/1 a}'].join(""), function(b) {
            a = b.offsetHeight >= 3
        }), a
    }, r.video = function() {
        var a = b.createElement("video"),
            c = !1;
        try {
            if (c = !!a.canPlayType) c = new Boolean(c), c.ogg = a.canPlayType('video/ogg; codecs="theora"').replace(/^no$/, ""), c.h264 = a.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, ""), c.webm = a.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/^no$/, "")
        } catch (d) {}
        return c
    }, r.audio = function() {
        var a = b.createElement("audio"),
            c = !1;
        try {
            if (c = !!a.canPlayType) c = new Boolean(c), c.ogg = a.canPlayType('audio/ogg; codecs="vorbis"').replace(/^no$/, ""), c.mp3 = a.canPlayType("audio/mpeg;").replace(/^no$/, ""), c.wav = a.canPlayType('audio/wav; codecs="1"').replace(/^no$/, ""), c.m4a = (a.canPlayType("audio/x-m4a;") || a.canPlayType("audio/aac;")).replace(/^no$/, "")
        } catch (d) {}
        return c
    }, r.localstorage = function() {
        try {
            return localStorage.setItem(g, g), localStorage.removeItem(g), !0
        } catch (a) {
            return !1
        }
    }, r.sessionstorage = function() {
        try {
            return sessionStorage.setItem(g, g), sessionStorage.removeItem(g), !0
        } catch (a) {
            return !1
        }
    }, r.webworkers = function() {
        return !!a.Worker
    }, r.applicationcache = function() {
        return !!a.applicationCache
    }, r.svg = function() {
        return !!b.createElementNS && !!b.createElementNS(q.svg, "svg").createSVGRect
    }, r.inlinesvg = function() {
        var a = b.createElement("div");
        return a.innerHTML = "<svg/>", (a.firstChild && a.firstChild.namespaceURI) == q.svg
    }, r.smil = function() {
        return !!b.createElementNS && /SVGAnimate/.test(l.call(b.createElementNS(q.svg, "animate")))
    }, r.svgclippaths = function() {
        return !!b.createElementNS && /SVGClipPath/.test(l.call(b.createElementNS(q.svg, "clipPath")))
    };
    for (var J in r) A(r, J) && (w = J.toLowerCase(), e[w] = r[J](), u.push((e[w] ? "" : "no-") + w));
    return e.input || I(), e.addTest = function(a, b) {
        if (typeof a == "object")
            for (var d in a) A(a, d) && e.addTest(d, a[d]);
        else {
            a = a.toLowerCase();
            if (e[a] !== c) return e;
            b = typeof b == "function" ? b() : b, typeof enableClasses != "undefined" && enableClasses && (f.className += " " + (b ? "" : "no-") + a), e[a] = b
        }
        return e
    }, B(""), h = j = null, e._version = d, e._prefixes = m, e._domPrefixes = p, e._cssomPrefixes = o, e.hasEvent = y, e.testProp = function(a) {
        return F([a])
    }, e.testAllProps = H, e.testStyles = x, e
}(this, this.document);